import React, { useState } from "react";
import { Container, Navbar, Nav, NavDropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Green from '../images/green.png'
import Red from '../images/red.png'

const NavCont = ({showAuth, setShowAuth, setCurrentUser, currentUser, handleAddDist, districts, siteSettings, handleLogout}) => {

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

    const handleNav = e => {
        e.preventDefault();
        setExpanded(false);
        navigate(e.target.name, {replace: true})
    }

    const handleAuth = e => {
        setShowAuth(!showAuth);
    }

    if(window.location.href.includes("#login")){
        setShowAuth(true);
        navigate(window.location.pathname, {replace: true});
    }

    const handleAdmin = e => {
        setCurrentUser({...currentUser, adminSettings: !currentUser.adminSettings});
    }

        return (
            <>
                
                    <Navbar expand="lg" bg="dark" variant="dark" expanded={expanded} className="navbar-dark bg-dark">
                        <Container>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbarBtn" onClick={() => setExpanded(!expanded)} />
                            <Navbar.Collapse id="collapsible-navbar-nav" variant="dark" bg="dark" className="justify-content-end">
                                <Nav className="justify-content-end">
                                    <Nav.Link 
                                        onClick={handleNav}
                                        className={window.location.href.split("/")[3] === ('') && 'current-nav-link'}
                                        name="/" active={
                                            window.location.href.split(':3000')[1] === '/' || 
                                            window.location.href.split('.com')[1] === '/' ||
                                            window.location.href.split('.net')[1] === '/' ||
                                            window.location.href.split('.org')[1] === '/'
                                        } 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-fill pb-1" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                                            <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                                        </svg>
                                        Home
                                    </Nav.Link>
                                    {siteSettings.galleryMenu && (
                                        <NavDropdown 
                                            active={window.location.href.includes('/more')} 
                                            className={window.location.href.includes('/more') && 'current-nav-link'}
                                            title={
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-images pb-1" viewBox="0 0 16 16">
                                                        <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                                                        <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>
                                                    </svg>
                                                    <span>Galleries</span>
                                                </>
                                            } id="basic-nav-dropdown"
                                        >
                                            {
                                                districts[0] && (
                                                    districts
                                                    .sort((a, b) => parseInt(a.district) > parseInt(b.district) ? 1 : -1)
                                                    .map(d => (
                                                        d.pageType === "gallery" && (
                                                            <NavDropdown.Item 
                                                                key={d._id}
                                                                name={`/more/${d.district.replace(" ", "_")}`} 
                                                                onClick={handleNav}
                                                            >
                                                                {d.district}
                                                            </NavDropdown.Item>
                                                        )
                                                    ))
                                                )
                                            }
                                        </NavDropdown>
                                    )}
                                    {siteSettings.fileMenu && (
                                        <NavDropdown 
                                            active={window.location.href.includes('/more')} 
                                            className={window.location.href.includes('/more') && 'current-nav-link'}
                                            title={
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-medical-fill pb-1" viewBox="0 0 16 16">
                                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-3 2v.634l.549-.317a.5.5 0 1 1 .5.866L7 7l.549.317a.5.5 0 1 1-.5.866L6.5 7.866V8.5a.5.5 0 0 1-1 0v-.634l-.549.317a.5.5 0 1 1-.5-.866L5 7l-.549-.317a.5.5 0 0 1 .5-.866l.549.317V5.5a.5.5 0 1 1 1 0zm-2 4.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"/>
                                                    </svg>
                                                    <span>Files</span>
                                                </>
                                            } id="basic-nav-dropdown"
                                        >
                                            {/* <NavDropdown.Item 
                                                name={`/recentdocuments`} 
                                                onClick={handleNav}
                                            >
                                                Recent Documents
                                            </NavDropdown.Item> */}
                                            {
                                                districts[0] && (
                                                    districts
                                                    .sort((a, b) => parseInt(a.district) > parseInt(b.district) ? 1 : -1)
                                                    .map(d => (
                                                        (d.pageType === 'file') && (
                                                            <NavDropdown.Item 
                                                                key={d._id}
                                                                name={`/more/${d.district.replace(" ", "_")}`} 
                                                                onClick={handleNav}
                                                            >
                                                                {d.district}
                                                            </NavDropdown.Item>
                                                        )
                                                    ))
                                                )
                                            }
                                        </NavDropdown>
                                    )}
                                    {
                                            districts[0] && (
                                                districts
                                                .sort((a, b) => parseInt(a.sortOrder) > parseInt(b.sortOrder) ? 1 : -1)
                                                .map(d => (
                                                    d.navLoc === "navbar" && (
                                                        <>
                                                        <Nav.Link 
                                                            key={d._id}
                                                            className={window.location.href.includes(d.district.replace(" ", "_")) && 'current-nav-link'}
                                                            active={window.location.href.includes(d.district.replace(" ", "_"))} 
                                                            name={`/${d.district.replace(" ", "_")}`} 
                                                            onClick={handleNav}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file pb-1" viewBox="0 0 16 16">
                                                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"/>
                                                            </svg>   
                                                            {d.district}
                                                        </Nav.Link >
                                                        </>
                                                    )
                                                ))
                                            )
                                        }
                                    <NavDropdown 
                                        active={window.location.href.includes('/more')} 
                                        className={window.location.href.includes('/more') && 'current-nav-link'}
                                        title={
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-binoculars-fill pb-1" viewBox="0 0 16 16">
                                                    <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z"/>
                                                </svg>
                                                <span>More</span>
                                            </>
                                        } id="basic-nav-dropdown"
                                    >
                                        {
                                            districts[0] && (
                                                districts
                                                .sort((a, b) => parseInt(a.sortOrder) > parseInt(b.sortOrder) ? 1 : -1)
                                                .map(d => (
                                                    (d.navLoc === "more" && d.pageType !== 'gallery' && d.pageType !== 'file') && (
                                                        <NavDropdown.Item 
                                                            key={d._id}
                                                            name={`/more/${d.district.replace(" ", "_")}`} 
                                                            onClick={handleNav}
                                                        >
                                                            {d.district}
                                                        </NavDropdown.Item>
                                                    )
                                                ))
                                            )
                                        }
                                        {(currentUser.isAuthenticated && currentUser.user.isAdmin > 9 && currentUser.adminSettings) && (
                                            <>
                                                <NavDropdown.Item name="/more/add" onClick={handleAddDist} className="text-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                                                    </svg>
                                                </NavDropdown.Item>
                                            </>
                                        )}
                                    </NavDropdown>
                                    <Nav.Link 
                                        onClick={handleNav} 
                                        className={window.location.href.includes('/about') && 'current-nav-link'}
                                        name="/about" active={window.location.href.includes('/about')} 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-square pb-1" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z"/>
                                        </svg>
                                        About
                                    </Nav.Link>
                                    {currentUser.isAuthenticated && (
                                        <NavDropdown title={
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill pb-1" viewBox="0 0 16 16">
                                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                            </svg>
                                            <span>{currentUser.user.username}</span>
                                            </>
                                        } id="basic-nav-dropdown"
                                        >
                                            {currentUser.user.isAdmin > 0 && (
                                                <>
                                                    { currentUser.user.isAdmin > 9 && (
                                                        <>
                                                            <NavDropdown.Item name='/users' onClick={handleNav}>Site Admin</NavDropdown.Item>
                                                            <NavDropdown.Divider />
                                                        </>
                                                    ) }
                                                    <Form.Check 
                                                        className="ms-2"
                                                        type="switch"
                                                        id="admin-switch"
                                                        label="Admin"
                                                        checked={currentUser.adminSettings}
                                                        onChange={handleAdmin}
                                                    />
                                                    <NavDropdown.Divider />
                                                </>
                                            )}
                                            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                        </NavDropdown>
                                    )}
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a href="https://www.facebook.com/w4olb" rel="noreferrer" target="_blank" className="nav-link active">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                            </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                
            </>
        );
}

export default NavCont;
                                