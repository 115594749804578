import React from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import skywarnEye from '../images/loading.gif'

const SmallLoading = () => {

    

   return (
        <Container className="">
            <Row className="text-center">
                <Col>
                    <img className='img-fluid' style={{height: '100px', width: 'auto'}} src={skywarnEye} />
                </Col>
            </Row>
        </Container>
   )
};

export default SmallLoading;
