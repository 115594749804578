import React, { useState } from 'react';
import { Button, Col, FloatingLabel, Modal, Row, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import NewImage from './NewImage';
import Select from 'react-select';

const DistrictSettings = ({district, showSettings, setShowSettings, currentUser, socket}) => {

    const navigate = useNavigate();

    const handleClose = () => {
        setShowSettings(false);
    }

    const [distClone, setDistClone] = useState(district);

    const handleSubmit = () => {
        let authToken = localStorage.getItem('auth_token');
        let userId = localStorage.getItem('_id') ? localStorage.getItem('_id') : "";
        let payload = { _id: userId, jwt: authToken, data: distClone}
        console.log(payload);
        socket.emit('district settings', payload);
        socket.on('district update', data => {
            window.location.reload(false);
        });
        handleClose();
    }

    const handleDelete = () => {
        let authToken = localStorage.getItem('auth_token');
        let userId = localStorage.getItem('_id') ? localStorage.getItem('_id') : "";
        socket.emit('district delete', { _id: userId, jwt: authToken, districtId: district._id });
        socket.on('district deleted', data => {
            console.log(data);
            navigate("/", {replace: true});
            window.location.reload();
        });
        handleClose();
    }

    const modeOptions = [
        { value: 'navbar', label: 'Top Navbar' },
        { value: 'more', label: 'More Dropdown' }
    ]
    
    return (
        <Modal className='text-black' show={showSettings} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{district.district} Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <label>Page Name</label>
                        <input value={distClone.district} onChange={e => setDistClone({...distClone, district: e.target.value})} className='form-control text-black' />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Select
                            isClearable
                            className='text-black'
                            placeholder="Page Type"
                            defaultValue={{value: district.pageType, label: district.pageType}}
                            onChange={e => setDistClone({...distClone, pageType: e.value})}
                            options={[
                                {value: "text/image", label: "Text/Image"},
                                {value: "file", label: "Files"},
                                {value: "gallery", label: "Image Gallery"}
                            ]}
                        />
                    </Col>
                </Row>
                <Row>
                    <Select 
                        placeholder="Nav Location"
                        className='text-black mt-3'
                        defaultValue={{value: district.navLoc, label: district.navLoc}}
                        closeMenuOnSelect={false}
                        onChange={e => setDistClone({...distClone, navLoc: e.value})}
                        options={modeOptions}
                    />
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <label>Menu Order</label>
                        <input onChange={e => setDistClone({...distClone, sortOrder: e.target.value})} type='number' className='text-black form-control' value={distClone.sortOrder} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NewImage 
                            currentUser={currentUser}
                            showButton={true}
                            socket={socket}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={handleDelete}>
                    Delete Page
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button onClick={handleSubmit} variant="primary">
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default DistrictSettings;
