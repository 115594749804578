import React, {useEffect, useState} from 'react';
import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Buffer } from 'buffer';
import Loading from './loading';
import ImageViewer from './Modals/ImageViewer';
import Dropzone from 'react-dropzone';

const NewGallery = ({currentUser, socket, setBanner, banner, d}) => {

    const [ reload, setReload ] = useState(true);

    useEffect(() => {
        if(reload){
            setBanner({...banner, loading: false, id: 0});
            setReload(false);
            window.scrollTo(0, 0);
        }
    }, [setBanner, setReload, banner, reload]);

    const [ loading, setLoading ] = useState(true);

    const [ images, setImages ] = useState([]);

    const [ imageViewer, setImageViewer ] = useState({
        show: false,
        image: null
    });

    const [ uploadImages, setUploadImages ] = useState({});

    const handleUpload = e => {
        e.preventDefault();
        // const input = document.getElementById('fileUpload');
        // const files = input.files;
        let payload = {
            images: uploadImages,
            pageId: d._id
        }
        socket.emit('store_images', payload);
        socket.on('images_stored', data => {
            setUploadImages({});
            setImages(data);
        });
    }

    const removeImage = e => {
        let answer = window.confirm("Delete this image? This action can not be undone.");
        if(answer){
            const payload = {
                image: e,
                userId: currentUser.user._id
            };
            socket.emit('remove image', payload);
            socket.on('image removed', () => {
                setImages([...images.filter(image => image._id !== e)]);
            });
        }
    }

    const setFiles = e => {
        setUploadImages(e);
    };

    useEffect(() => {
        if(loading && !images.length){
            socket.emit('load images', {pageId: d._id});
            socket.on('images loaded', data => {
                setImages(data);
                setLoading(false);
            });
        }
    });

    return (
        <>
            {!loading ? (
                <>
                    {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings) && (
                        <Row className='mt-1 mb-1' style={{width: '100%'}}>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Form onSubmit={handleUpload}>
                                            <Row>
                                                <Col>
                                                    <Dropzone 
                                                        onDrop={e => setFiles(e)}
                                                        multiple={true}
                                                    >
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                                <div {...getRootProps()} className='text-center dropbox-border'>
                                                                    <input {...getInputProps()} />
                                                                    <h5>Drop your file here or click to select.</h5>
                                                                    <p>16MB Size Limit</p>
                                                                    {uploadImages.length && (
                                                                        <div><p>{uploadImages.length} {uploadImages.length > 1 ? "images" : "image"} attached</p></div>
                                                                    )}
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='d-flex justify-content-end'>
                                                    <Button type='submit' className='btn-block mt-3'>Upload</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <Row style={{width: '100%'}}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h3 className='text-center mt-1'>{d.district}</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {images.map((data, index) => (
                            (data.image && data.type === "gallery") && (
                                <Col md={3} key={index} className='p-3'>
                                    <div className="img-card">
                                        <Image 
                                            onClick={() => setImageViewer({show: true, image: data.image})}
                                            style={{ width: '100%', height: "auto" }}
                                            thumbnail
                                            fluid={true}
                                            src={`data:image/png;base64,${Buffer.from(data.image).toString('base64')}`}
                                        />
                                        {(imageViewer.show && data.image === imageViewer.image) && (
                                            <ImageViewer 
                                                image={images[index].image}
                                                imageViewer={imageViewer}
                                                setImageViewer={setImageViewer}
                                            />
                                        )}
                                        {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings) && (
                                            <Button 
                                                className='mt-1' 
                                                type='danger'
                                                onClick={() => removeImage(data._id)}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            )
                        ))}
                    </Row>
                </>
            ) : (
                <Loading />
            )}
        </>
    );
}


export default NewGallery;
