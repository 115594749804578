
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import NewEditor from '../TextEditor/NewEditor';
import Editor from '../TextEditor/Editor';
import DistrictSettings from '../Modals/DistrictSettings';
import PageTextImage from './PageTextImage';
import NewGallery from '../NewGallery';
import Docs from '../Docs';

const DistrictPage = ({currentUser, districts, dispatchPosts, posts, banner, setBanner, socket}) => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const [lastId, setLastId] = useState();

    const [showSettings, setShowSettings] = useState(false);

    const handleDistrictSettings = () => {
        setShowSettings(true);
    }

    useEffect(() => {
        if(id !== lastId){
            setLoading(true);
            setLastId(id);
        }
        if(loading){
            setBanner({loading: false, id: 0});
            setLoading(false);
            window.scrollTo(0, 0);
        }
    })

    return (
        districts.map(d => (
            d.district.toString() === id.replace("_", " ") && (
                <>
                    <Row>
                        <Col className="cursor-pointer d-flex justify-content-center">
                        {(currentUser.isAuthenticated && (currentUser.user.isAdmin > 8 || (currentUser.user.isAdmin.toString() === id && window.location.href.includes('district'))) && currentUser.adminSettings) && (
                            <Card className="shadow-lg">
                                <Card.Body className="justify-content-center">
                                    <h3 className="card-title text-center">
                                        <div>
                                            {d.district} Settings
                                        </div>
                                        <div className='text-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={handleDistrictSettings} width="20" height="20" fill="currentColor" className="bi bi-gear-fill mt-1 mb-1 cursor-pointer" viewBox="0 0 16 16">
                                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                            </svg>
                                        </div>
                                    </h3>
                                </Card.Body>
                            </Card>
                        )}
                        </Col>
                    </Row>
                    {d.pageType === "text/image" ? (
                        <PageTextImage
                            posts={posts}
                            dispatchPosts={dispatchPosts}
                            currentUser={currentUser}
                            socket={socket}
                            d={d}
                        />
                    ) : d.pageType === 'gallery' ? (
                        <NewGallery 
                            currentUser={currentUser}
                            setBanner={setBanner}
                            banner={banner}
                            socket={socket}
                            d={d}
                        />
                    ) : d.pageType === 'file' && (
                        <Docs 
                            currentUser={currentUser}
                            socket={socket}
                            setBanner={setBanner}
                            banner={banner}
                            d={d}
                        />
                    )}
                    <DistrictSettings 
                        district={d}
                        showSettings={showSettings}
                        setShowSettings={setShowSettings}
                        currentUser={currentUser}
                        socket={socket}
                    />
                </>
            )
        ))
    );
};

export default DistrictPage;