import React, { useState } from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import SmallLoading from '../SmallLoading';
import axios from 'axios';

const NewDoc = ({currentUser, socket, d, docList, setDocList}) => {

    const url = process.env.REACT_APP_SOCKET_URL;


    const [show, setShow] = useState(false);
    const [uploading, setUploading] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const [ file, setFile ] = useState([]);

    const handleFile = async e => {
        setFile(e.target.files);
    }

    const handleUpload = (e) => {
        if(!e){
            socket.emit('error_redirect', { message: 'There are no files attached.', type: "danger" });
        } else {
            setUploading(true);

            let payload = {
                id: currentUser.user._id,
                jwt: window.localStorage.getItem('auth_token'),
                pageId: d._id,
                fileName: e[0].name,
                fileType: e[0].type,
                file: e[0]
            };
            console.log(payload)
            socket.emit('new doc', payload);
            socket.on('doc stored', data => {
                setDocList([...docList, data]);
                setUploading(false);
            });
        }
    }
    
    return (
        <>
        {( currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings ) && (
        <Row>
            <Col className='d-flex justify-content-center'>
                {!uploading ? (
                    <Dropzone 
                        onDrop={handleUpload}
                        multiple={true}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section  style={{width: '99%'}}>
                                <div {...getRootProps()} className='text-center dropbox-border'>
                                    <input {...getInputProps()} />
                                    <h5>Drop your file here or click to select.</h5>
                                    <p>16MB Size Limit</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                ) : (
                    <div className='text-center'>
                    <h3 className='text-center'>Uploading Files...</h3>
                    <SmallLoading />
                    </div>
                )}
            </Col>
        </Row>
        )}
        </>
    )
};

export default NewDoc;
