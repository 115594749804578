import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const Error = ({error, setError}) => {

    const handleClose = () => {
        setError({...error, message: "", show: false});
    }
    
    return (
        <Modal className='text-black' show={error.show} onHide={handleClose}>
            <Modal.Header style={{backgroundColor: error.type === "success" ? "green" : "red"}} closeButton>
                <Modal.Title>{error.type === "success" ? "Success" : "Error"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{error.message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default Error;
