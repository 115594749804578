import React, { useState } from 'react';
import {Row, Container, Form, Button, Table, Col} from 'react-bootstrap';
import Loading from '../loading';
import Select from 'react-select';
import GeoLocModal from '../Modals/GeoLoc';

const UserManage = ({currentUser, setCurrentUser, socket, siteSettings, setSiteSettings}) => {

    const [ loading, setLoading ] = useState(true);

    const [ users, setUsers ] = useState([]);

    const [ user, setUser ] = useState({});

    if(loading){
        let payload = {
            requestor: currentUser.user,
            jwt: localStorage.getItem('auth_token'),
            ip: localStorage.getItem('ip')
        }
        socket.emit('load users', payload);
        socket.on('users loaded', data => {
            setUsers(data);
            setLoading(false);
        });
    }

    const handleSubmit = e => {
        e.preventDefault();
        let payload = {
            user,
            requestor: currentUser.user,
            jwt: localStorage.getItem('auth_token')
        }
        socket.emit('update user', payload);
    }

    const handleGalleryChange = async (e) => {
        await setSiteSettings({...siteSettings, galleryMenu: e.value});
        socket.emit('update_site_settings', await siteSettings);
    }

    const handleFileChange = async (e) => {
        console.log(e);
        await setSiteSettings({...siteSettings, fileMenu: e.value});
        socket.emit('update_site_settings', await siteSettings);
    }

    const adminOptions = [
        {value: 0, label: 'User'},
        {value: 1, label: 'NCS'},
        {value: 2, label: 'Editor'},
        {value: 10, label: 'Admin'}
    ];

    const boolOptions = [
        {value: true, label: 'Yes'},
        {value: false, label: 'No'}
    ];

    const [ geoData, setGeoData ] = useState({
        user: {},
        show: false
    })

    return (
            <Container fluid className="mt-3">
                <Row className='text-center'>
                    <Col>
                        <h3>Site Settings</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Show Image Gallery Menu (2x select to confirm)</label>
                        <Select 
                            className='text-black'
                            defaultValue={ siteSettings.galleryMenu ? 
                                ({label: 'Yes', value: true}) : 
                                ({label: 'No', value: false}) 
                            }
                            options={boolOptions}
                            onChange={e => handleGalleryChange(e)}
                        />    
                    </Col>
                    <Col>
                        <label>Show File Menu (2x select to confirm)</label>
                        <Select 
                            className='text-black'
                            defaultValue={ siteSettings.fileMenu ? 
                                ({label: 'Yes', value: true}) : 
                                ({label: 'No', value: false}) 
                            }
                            options={boolOptions}
                            onChange={e => handleFileChange(e)}
                        />    
                    </Col>
                </Row>
                <Row className="mt-5 text-center">
                    <Col>
                        <h3>Edit Users</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    {!loading ? (
                        <Container fluid>
                            <Table striped bordered hover variant='dark' className='shadow-lg'>
                                <thead>
                                    <tr>
                                        <th>Callsign</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Admin Level</th>
                                        <th>Geo</th>
                                        <th>Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(u => (
                                        <tr>
                                            <td>
                                                <Form.Control 
                                                    onChange={e => setUser({...u, username: e.target.value})}
                                                    defaultValue={u.username} 
                                                    disabled={!u.isAdmin > 8} 
                                                />
                                            </td>
                                            <td>
                                                <Form.Control 
                                                    onChange={e => setUser({...u, firstName: e.target.value})}
                                                    defaultValue={u.firstName} 
                                                    disabled={!u.isAdmin > 8} 
                                                />
                                            </td>
                                            <td>
                                                <Form.Control 
                                                    onChange={e => setUser({...u, lastName: e.target.value})}
                                                    defaultValue={u.lastName} 
                                                    disabled={!u.isAdmin > 8} 
                                                />
                                            </td>
                                            <td>
                                                <Form.Control 
                                                    onChange={e => setUser({...u, email: e.target.value})}
                                                    defaultValue={u.email} 
                                                    disabled={!u.isAdmin > 8} 
                                                />
                                            </td>
                                            <td>
                                                <Select 
                                                    className='text-black'
                                                    defaultValue={adminOptions.filter(a => a.value === u.isAdmin)}
                                                    options={adminOptions}
                                                    onChange={e => setUser({...u, isAdmin: e.value})}
                                                />    
                                            </td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setGeoData({show: true, user: u})} width="22" height="22" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16">
                                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                                    <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                                </svg>
                                            </td>
                                            <td><Button onClick={handleSubmit}>Update</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <GeoLocModal 
                                geoData={geoData}
                                setGeoData={setGeoData}
                            />
                        </Container>
                    ) : (
                        <Loading />
                    )}
                    </Col>
                </Row>
            </Container>
    )
};

export default UserManage;