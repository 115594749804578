import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Logo from '../images/Decad3Logo.webp'
import { useNavigate } from 'react-router-dom';

const Footer = ({error, setError, districts}) => {

    const navigate = useNavigate();

    const handleNav = e => {
        e.preventDefault();
        navigate(e.target.name, {replace: true})
    }
    
    return (
        <Container fluid className='mt-5'>
            <hr />
            <div className="row text-center d-flex justify-content-center mb-4">
                <div className="col-md-1">
                    <a href="https://www.facebook.com/w4olb" rel="noreferrer" target="_blank" className="nav-link active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div className="row text-center d-flex justify-content-center">
                <div className="col-md-3 col-sm-12">
                    <div>
                        <h4>Site Links</h4>
                    </div>
                    <a className="nav-link no-button button-link" onClick={handleNav} name="/">Home</a>
                    <a className="nav-link no-button button-link" onClick={handleNav} name="/gallery">Gallery</a>
                    <a className="nav-link no-button button-link" onClick={handleNav} name="/documents">Documents</a>
                    <a className="nav-link no-button button-link" onClick={handleNav} name="/about">About</a>
                </div>

                <div className="col-md-3 col-sm-12">
                    <div>
                        <h4>More</h4>
                    </div>
                    <Row>
                        {districts.map(d => (
                            <Col md={4} sm={6}>
                                <a className="nav-link no-button button-link" onClick={handleNav} name={`/more/${d.district}`}>{d.district}</a>
                            </Col>
                        ))}
                    </Row>
                </div>

                <div className="col-md-3 col-sm-12">
                    <div>
                        <h4>Other Links</h4>
                    </div>
                    <span className="nav-link no-button button-link" target="_blank" rel="noreferrer" href="https://etskywarn.com">ET SKYWARN</span>
                </div>
            </div>
            <br />
            <div className="container">
                <div className="row text-center">
                    <a href="https://decad3.com">
                        <img target="__blank" rel="noreferrer" src={Logo} alt="Decad3 Logo" style={{width: '200px', height: 'auto'}} />
                    </a>
                </div>
                <div className="row text-center">
                    <p>©2024 W4OLB</p>
                </div>
            </div>
        </Container>
    )
};

export default Footer;