import React, {useState} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import unsure from '../images/unsure.gif';

const NotFound = () => {

    const [ loading, setLoading ] = useState(true);

    if(loading){
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }


    return (
        <Container className='mt-4 pb-4'>
            {!loading ? (
                <>
                    <Row className='text-center'>
                        <Col>
                            <div className='text-center h-100 d-flex justify-content-center'>
                                <h1 className='belowNav'>
                                    We can't find what you're looking for...
                                </h1>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='text-center h-100 d-flex justify-content-center'>
                                <div className='mt-5 pt-4'>
                                    <img className='mt-4' alt={'Thinking Man'} src={unsure} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            ) : (
                <div style={{height: '300px'}} />
            )}
        </Container>
    );
}


export default NotFound;
