
import React, {useMemo, useRef} from 'react';
import PostEdit from './PostEdit';
import ImageResize from 'quill-image-resize-module-react';
import { Quill } from 'react-quill';
import axios from 'axios';

var Image = Quill.import('formats/image');
Image.className = 'img-fluid';
Quill.register(Image, true);

const Editor = ({post, currentUser, dispatchPosts, socket}) => {

    const quillRef = useRef();

    Quill.register('modules/imageResize', ImageResize);

    function insertToEditor(url) {
        quillRef.current.getEditor().insertEmbed(null, "image", url);
    }
    
    const imageHandler = (a) => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            // file type is only image.
            if (/^image\//.test(file.type)) {
                console.log(file.type);
                try {
                    let payload = {id: currentUser.user._id, file};
                    if(file.type){
                        socket.emit('store image', payload);
                    }
                    socket.on('image stored', data => {
                        let url = process.env.REACT_APP_SOCKET_URL + '/image/' + data;
                        insertToEditor(url);
                    });
                } catch (error) {
                    console.log(error);
                }
            } else {
                console.warn("You can only upload images.");
            }
        };
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['image', "link",],
              [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
            ],
            handlers: {
              image: imageHandler
            },
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }));

    return (
        <PostEdit
            dispatchPosts={dispatchPosts}
            currentUser={currentUser}
            post={post}
            modules={modules}
            quillRef={quillRef}
            socket={socket}
        />
    );
};

export default Editor