import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Editor from '../TextEditor/Editor';
import NewEditor from '../TextEditor/NewEditor';

const PageTextImage = ({posts, dispatchPosts, currentUser, socket, d}) => {

  return (
    <>
        <Row>
            {posts
            .sort((a, b) => a.order > b.order ? 1 : -1)
            .map(p => (
                p.type === `district${d.district}` && (
                    <Col key={p._id} md={12} className="d-flex justify-content-center">
                        <Card className="shadow-lg">
                            <Card.Body className="justify-content-center">
                                <h3 className="card-title text-center">
                                    {p.title}
                                </h3>
                                <div dangerouslySetInnerHTML={{__html: p.text}} />
                                <Editor
                                    dispatchPosts={dispatchPosts}
                                    currentUser={currentUser}
                                    post={p}
                                    socket={socket}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                )
            ))}
        </Row>
        <Row>
            <Col className='d-flex justify-content-center'>
                {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings || 
                currentUser.isAuthenticated && currentUser.user.isAdmin === d.district && currentUser.adminSettings) && (
                    <Card className='text-center justify-content-center cursor-pointer shadow-lg'>
                        <NewEditor
                            dispatchPosts={dispatchPosts}
                            currentUser={currentUser}
                            type={`district${d.district}`}
                            socket={socket}
                        />
                    </Card>
                )}
            </Col>
        </Row>
    </>
  );
};

export default PageTextImage;