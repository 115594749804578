import React from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import skywarnEye from '../images/loading.gif'

const Loading = ({height}) => {

    if(height === undefined) {
        height='30vh';
    }

   return (
        <Container className="pb-1 mb-5 mt-5">
            <Row className="mb-5 text-center">
                <Col>
                    <h3>Loading...</h3>
                    <img className='img-fluid' style={{height: `${height}`, width: 'auto'}} src={skywarnEye} />
                </Col>
            </Row>
        </Container>
   )
};

export default Loading;
