import React, { useState } from 'react';
import {Button, Table} from 'react-bootstrap';
import TableContainer from './TableContainer';
import { Form } from 'react-router-dom';

const CustomTable = () => {

    const [ loading, setLoading ] = useState(true);

    const [ data, setData ] = useState([{Name: "Jordan", Age: '34', State: 'TN'}, {Name: "Sam", Age: '40', State: 'TN'}]);

    const [ editColumns, setEditColumns ] = useState(false);
    const [ editRows, setEditRows ] = useState(false);

    const handleColChange = e => {
        console.log(e.target.value)
    }

    return (
        <>
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        {Object.entries(data[0]).map((key, value) => (
                            <th>{!editColumns ? (key[0]) : (
                                <input type='text' defaultValue={key[0]} onChange={handleColChange} />
                            )}</th>
                        ))}
                        <th>
                            {!editColumns ? (
                                <svg onClick={() => setEditColumns(!editColumns)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                </svg>
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(d => (
                        <tr>
                            {Object.entries(d).map((key, value) => (
                                <td>{!editRows ? (key[1]) : (
                                    <input type='text' defaultValue={key[1]} onChange={null} />
                                )}</td>
                            ))}
                            <td>
                                {!editRows ? (
                                    <svg onClick={() => setEditRows(!editRows)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                                    </svg>
                                ) : (
                                    <Button type='button' onClick={() => setEditRows(false)}>Submit</Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            
        </>
    )
};

export default CustomTable;
