import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { io } from 'socket.io-client';

const DecisionCheck = ({ check, setCheck, title, body, socketName, id }) => {

  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;

  const handleClose = () => {
    setCheck({ show: false, proceed: false });
  };

  const handleDelete = () => {
    const socket = io(ENDPOINT, { transports: ['websocket'] });

    const payload = {
      userId: localStorage.getItem('id'),
      id,
    };

    socket.emit(socketName, payload);
    handleClose();
  };
  
  return (
    // Render the Modal component with the specified props
    <Modal className='text-black' show={check.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setCheck({ ...check, show: false })}>
          No
        </Button>
        <Button variant='danger' onClick={handleDelete}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DecisionCheck;