
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Articles from '../Articles';
import Loading from '../loading';
import Districts from './Districts';
import NewImage from '../Modals/NewImage';

const Home = ({ loading, loadingPosts, loadingDistricts, posts, currentUser, dispatchPosts, districts, banner, setBanner, socket }) => {

    const [ reload, setReload ] = useState(true);

    useEffect(() => {
        if(reload){
            setBanner({...banner, loading: false, id: 0});
            setReload(false);
            window.scrollTo(0, 0);
        }
    }, [setBanner, setReload, banner, reload]);

    return (
            (!loadingDistricts) ? (
                (!loadingPosts) ? (
                    <>
                        <Articles
                            isLoading={loading}
                            data={posts}
                            dispatchPosts={dispatchPosts}
                            currentUser={currentUser}
                            type='home'
                            socket={socket}
                        />
                        <NewImage
                            currentUser={currentUser}
                            banner={banner}
                            setBanner={setBanner}
                            socket={socket}
                        />
                    </>
                ) : (
                    <Loading/>
                )
            ) : (
                <Loading/>
            )
    );
    
};

export default Home