
import React, { useState } from 'react';
import { Button, Container, FloatingLabel, Form, Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import CustomTable from '../Table/CustomTable';


const PostEdit = ({post, currentUser, dispatchPosts, modules, quillRef, socket}) => {

    const [show, setShow] = useState(false);
    const [editRaw, setEditRaw] = useState(false);
    const handleClose = () => {
        setEditRaw(false);
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const [ newPost, setNewPost ] = useState({...post});

    const [ isTable, setIsTable ] = useState(false);

    const handlePost = e => {
        e.preventDefault();
        let payload = {id: currentUser.user._id, jwt: currentUser.user.jwt, newPost};
        socket.emit('update post', payload);
        socket.on('post updated', data => {
            dispatchPosts({data, type: "UPDATE_POST"});
        });
        handleClose();
    }

    const handleDelete = e => {
        e.preventDefault();
        let payload = {id: currentUser.user._id, jwt: currentUser.user.jwt, post, ip: localStorage.getItem('ip')};
        socket.emit('delete post', payload);
        socket.on('post deleted', data => {
            dispatchPosts({data, type: 'REMOVE_POST'});
        })
        handleClose();
    }

    const handleSetPost = e => {
        setNewPost({...newPost, text: e});
    }

    const handleVisible = () => {
        setNewPost({...newPost, visible: !newPost.visible});
    }

    return (
        <Container fluid>
            <>
            {(currentUser.user.isAdmin > 9 && currentUser.adminSettings) && (
                <div className="text-center">
                    <button className='no-button' variant="none" onClick={handleShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                    </button>
                </div>
            )}
            <Modal show={show} size="lg" fullscreen={true} dialogClassName="net-modal" onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Edit Article</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: "#585858"}} className='text-black'>
                    <FloatingLabel controlId="floatingTitle" label="Title">
                        <Form.Control 
                            type="text" 
                            value={newPost.title} 
                            onChange={
                                e => setNewPost({...newPost, title: e.target.value})
                            }
                        />
                    </FloatingLabel>
                    <div className='mt-3'>
                        <Select 
                            onChange={e => setNewPost({...newPost, size: e})}
                            options={[
                                { value: 12,label:'Full' },
                                { value: 9,label:'3/4' },
                                { value: 8,label:'2/3' },
                                { value: 6,label:'1/2' },
                                { value: 4,label:'1/3' },
                                { value: 3,label:'1/4' },
                            ]}
                        />
                    </div>
                    <FloatingLabel controlId="floatingTitle" label="Order" className='mt-2'>
                        <Form.Control 
                            type="text" 
                            value={newPost.order} 
                            onChange={
                                e => setNewPost({...newPost, order: e.target.value})
                            }
                        />
                    </FloatingLabel>
                    <span>
                        <Form.Check 
                            className='mt-3'
                            type="switch"
                            id="custom-switch"
                            label="Post Visible"
                            checked={newPost.visible}
                            onChange={handleVisible}
                        />
                    </span>
                    <span>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            label="Raw HTML"
                            className='mt-3'
                            defaultValue={editRaw}
                            onChange={e => setEditRaw(!editRaw)}
                        />
                    </span>
                    <span>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            label="Make a Table"
                            className='mt-3'
                            defaultValue={isTable}
                            onChange={e => setIsTable(!isTable)}
                        />
                    </span>
                    <div className='mt-3'>
                        { isTable ? (
                            <CustomTable 
                                
                            />
                        ) : (
                            editRaw ? (
                                <FloatingLabel controlId="floatingTextarea2" label="Raw HTML Editor">
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '500px' }}
                                        value={newPost.text} 
                                        onChange={e => setNewPost({...newPost, text: e.target.value})} 
                                    />
                                </FloatingLabel>
                            ) : (
                                <ReactQuill 
                                    theme="snow" 
                                    style={{backgroundColor: "#585858"}}
                                    ref={quillRef} 
                                    value={newPost.text} 
                                    modules={modules} 
                                    formats={[
                                        'header', 'font', 'size', 'color',
                                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                                        'list', 'bullet', 'indent',
                                        'link', 'image', 'video',
                                        'align', 'width', 'height', 'float',
                                    ]}
                                    onChange={(e) => {
                                        handleSetPost(e)
                                    }}
                                />
                            )
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor: "#585858"}}>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="warning" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handlePost}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            </>
        </Container>
    );
};

export default PostEdit