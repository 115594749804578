import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import Moment from 'react-moment';

const GeoLocModal = ({geoData, setGeoData}) => {

    const handleClose = () => {
        setGeoData({...geoData, show: false});
    }
    
    return (
        geoData.show && (
            <Modal className='text-black' show={geoData.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Geo Loc Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {geoData.user.geoData[0] ? (
                        <>
                            <p>Lat: <span>{geoData.user.geoData[0].latitude}</span></p>
                            <p>Lon: <span>{geoData.user.geoData[0].longitude}</span></p>
                            <p>Zip: <span>{geoData.user.geoData[0].zipCode}</span></p>
                            <p>IP: <span>{geoData.user.ip}</span></p>
                            <p>Last Update: <Moment>{geoData.user.geoData[0].dateTime}</Moment></p>
                        </>
                    ) : (
                        <p>No Data Available</p>
                    )}
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    )
};

export default GeoLocModal;
