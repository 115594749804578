import React, { useEffect, useState } from 'react';
import {Button, Form, Modal} from 'react-bootstrap';

const NewImage = ({currentUser, showButton, socket}) => {

    const [show, setShow] = useState(false);

    const [ id, setId ] = useState(null);

    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        if(loading){
            if(window.location.href.includes('more/')){
                setId(window.location.href.split('more/')[1]);
                setLoading(false);
            } else {
                setId('0');
                setLoading(false);
            }
        }
    }, [loading])
    

    const handleClose = () => {
        setShow(false);
    }

    const [ file, setFile ] = useState(null);

    const handleFile = e => {
        console.log(e.target.files[0])
        setFile(e.target.files[0]);
    }

    const handleSubmit = () => {
        // file type is only image.
        if (/^image\//.test(file.type)) {
            let payload = {
                id: currentUser.user._id, 
                file, 
                type: id, 
                ip: localStorage.getItem('ip'), 
                jwt: localStorage.getItem('auth_token')
            }
            socket.emit('store image', payload);
            setShow(false);
        } else {
            console.warn("You can only upload images.");
        }
    };
    
    return (
        <>
        {((currentUser.isAuthenticated && !window.location.href.includes('repeater')) && (currentUser.user.isAdmin > 8 || currentUser.user.isAdmin.toString() === id) && currentUser.adminSettings) ? (
            <button className='no-button banner-button' onClick={e => {setShow(true); setLoading(true)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" fillColor='white' height="18" className="bi bi-pencil-fill cursor-pointer ms-2" viewBox="0 0 18 18">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                </svg>
            </button>
        ) : ((currentUser.isAuthenticated && window.location.href.includes('repeater') && showButton) && (currentUser.user.isAdmin > 8 || currentUser.user.isAdmin.toString() === id) && currentUser.adminSettings) && (
            <Button variant='secondary' className='mt-2' onClick={e => {setShow(true); setLoading(true)}}>
                Change Banner
            </Button>
        )}
        <Modal className='text-black shadow-lg' show={show} onHide={e => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>New Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Default file input example</Form.Label>
                        <Form.Control onChange={handleFile} type="file" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor: 'black'}}>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Upload
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
};

export default NewImage;
