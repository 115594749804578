import React, { useEffect, useState } from 'react';
import {Alert, Button, Col, Modal, Row} from 'react-bootstrap';

const ForgotPassword = ({forgotPassword, setForgotPassword, existingUsername, socket}) => {

    const [ username, setUsername ] = useState(existingUsername);

    const [ otp, setOtp ] = useState({
        fetch: false,
    });

    const [ pin, setPin ] = useState(null);

    const [ userId, setUserId ] = useState(null);

    const [ showOtp, setShowOtp ] = useState(false);

    const [ showChangePW, setShowChangePW ] = useState(false);

    const [mismatch, setMismatch] = useState(false);

    const [ pw, setPw ] = useState({
        password: null,
        password2: null
    });

    useEffect(() => {
        if(otp.fetch){
            socket.emit("fetch otp", username);
            socket.on('otp completed', () => {
                setShowOtp(true);
                setOtp({...otp, fetch: false});
            });
        }
        if(pw.password !== pw.password2){
            setMismatch(true);
        } else {
            setMismatch(false);
        }
    }, [socket]);

    const handleSubPin = () => {
        let payload = {
            username,
            pin
        }
        socket.emit('send otp', payload);
        socket.on('otp correct', data => {
            if(data.state === 'success'){
                console.log(data);
                setPin(data.token);
                setUserId(data.id);
                setShowOtp(false);
                setShowChangePW(true);
            }
        });
    }

    const handleChangePassword = () => {
        let payload = {
            username,
            userId,
            pin,
            password: pw.password
        }
        socket.emit('change password', payload);
        socket.on('password changed', () => {
            setShowChangePW(false);
        });
    }
    
    return (
        <>
            <Modal className='text-black' show={forgotPassword} onHide={() => setForgotPassword(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label">Callsign</label>
                        <input type="text" name="username" onChange={e => setUsername(e.target.value.toUpperCase())} value={username} className="form-control shadow" required />
                    </div>
                    <Row>
                        <Col>
                            <p>Please enter your callsign and a One Time Pin will be sent to the email address on file.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type='submit' onClick={() => {setForgotPassword(false); setShowOtp(true)}}>
                        Already Have Pin
                    </Button>
                    <Button variant="primary" type='submit' onClick={() => {setForgotPassword(false); setOtp({...otp, fetch: true}); setShowOtp(true)}}>
                        Get PIN
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className='text-black' show={showOtp} onHide={() => setShowOtp(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter One Time PIN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label">PIN</label>
                        <input type="number" name="pin" onChange={e => setPin(e.target.value)} value={otp.pin} className="form-control shadow" required />
                    </div>
                    <Row>
                        <Col>
                            <p>Please enter the One Time Pin received in your email.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type='button' onClick={handleSubPin}>
                        Verify PIN
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className='text-black' show={showChangePW} onHide={() => setShowOtp(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" onChange={e => setPw({...pw, password: e.target.value})} value={pw.password} className="form-control shadow" required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Verify Password</label>
                        <input type="password" onChange={e => setPw({...pw, password2: e.target.value})} value={pw.password2} className="form-control shadow" required />
                    </div>
                    { mismatch && (
                        <Row>
                            <Col>
                                <Alert variant='danger'>Passwords do not match</Alert>
                            </Col>
                        </Row>
                    ) }
                    <Row>
                        <Col>
                            <p>Please create a new password.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type='button' onClick={handleChangePassword}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ForgotPassword;
