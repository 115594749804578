import React, { useEffect } from 'react';
import NewImage from './Modals/NewImage';

const Banner = ({currentUser, banner, setBanner, socket}) => {

    let path;
    useEffect(() => {
        if(banner.loading){
            path = `${process.env.REACT_APP_SOCKET_URL}/banner/${banner.id}`;
        }
    });

    return (
        !banner.loading && (
            <div className='banner-container'>
                <img className="banner-img img-fluid" src={`${process.env.REACT_APP_SOCKET_URL}/banner/${banner.id}`} alt="W4OLB Banner" />
                <NewImage 
                    currentUser={currentUser}
                    banner={banner}
                    setBanner={setBanner}
                    socket={socket}
                />
            </div>
        )
    );
}


export default Banner;
