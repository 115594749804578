
import React, { useState } from 'react';
import { Button, Card, Container, FloatingLabel, Form, Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import Select from 'react-select';


const NewPostEdit = ({currentUser, dispatchPosts, modules, quillRef, type, socket}) => {

    const [show, setShow] = useState(false);

    const [editRaw, setEditRaw] = useState(false);

    let district;
    if(window.location.href.includes('district')){
        district = window.location.href.split("/")[4];
    }

    const handleClose = () => {
        setEditRaw(false);
        setShow(false);
    };

    const [ newPost, setNewPost ] = useState({
        title: "",
        text: "",
        visible: true,
        order: 0,
        type: type,
        size: null
    });

    const handlePost = e => {
        e.preventDefault();
        let payload = {id: currentUser.user._id, jwt: currentUser.user.jwt, newPost, ip: localStorage.getItem('ip')};
        socket.emit('create post', payload);
        socket.on('new post', data => {
            console.log(data);
            dispatchPosts({data, type: 'NEW_POST'});
        });
        handleClose();
    }

    const handleSetPost = e => {
        setNewPost({...newPost, text: e});
    }

    const handleVisible = () => {
        setNewPost({...newPost, visible: !newPost.visible});
    }

    return (
        <Container fluid>
            <>
                <Card.Body onClick={e => setShow(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                    </svg>
                </Card.Body>
                <Modal show={show} dialogClassName="net-modal" fullscreen={true} size="lg" onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>New Article</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{backgroundColor: "#585858"}} className='text-black'>
                        <FloatingLabel controlId="floatingTitle" label="Title">
                            <Form.Control 
                                type="text" 
                                required
                                value={newPost.title} 
                                onChange={
                                    e => setNewPost({...newPost, title: e.target.value})
                                }
                            />
                        </FloatingLabel>
                        <div className='mt-3'>
                            <Select 
                                onChange={e => setNewPost({...newPost, size: e})}
                                options={[
                                    { value: 12,label:'Full' },
                                    { value: 9,label:'3/4' },
                                    { value: 8,label:'2/3' },
                                    { value: 6,label:'1/2' },
                                    { value: 4,label:'1/3' },
                                    { value: 3,label:'1/4' },
                                ]}
                            />
                        </div>
                        <FloatingLabel controlId="floatingTitle" className='mt-3' label="Order">
                            <Form.Control 
                                type="number" 
                                required
                                value={newPost.order} 
                                onChange={
                                    e => setNewPost({...newPost, order: e.target.value})
                                }
                            />
                        </FloatingLabel>
                        <span>
                            <Form.Check 
                                className='mt-3'
                                type="switch"
                                id="custom-switch"
                                label="Post Visible"
                                checked={newPost.visible}
                                onChange={handleVisible}
                            />
                        </span>
                        <span>
                            <Form.Check 
                                className='mt-3'
                                type="switch"
                                id="custom-switch"
                                label="Raw HTML"
                                defaultValue={editRaw}
                                onChange={e => setEditRaw(!editRaw)}
                            />
                        </span>
                        <div className='mt-3'>
                            {
                                editRaw ? (
                                    <FloatingLabel controlId="floatingTextarea2" label="Raw HTML Editor">
                                        <Form.Control
                                            as="textarea"
                                            style={{ height: '500px' }}
                                            value={newPost.text} 
                                            onChange={e => setNewPost({...newPost, text: e.target.value})} 
                                        />
                                    </FloatingLabel>
                                ) : (
                                    <ReactQuill 
                                        style={{backgroundColor: "#585858"}}
                                        theme="snow" 
                                        ref={quillRef} 
                                        value={newPost.text} 
                                        modules={modules} 
                                        formats={[
                                            'header', 'font', 'size', 'color',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent',
                                            'link', 'image', 'video',
                                            'align', 'width', 'height', 'float',
                                        ]}
                                        onChange={(e) => {
                                            handleSetPost(e)
                                        }}
                                    />
                                )
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{backgroundColor: "#585858"}}>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={handlePost}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        </Container>
    );
};

export default NewPostEdit